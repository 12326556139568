import React from 'react'

import styled from 'styled-components'

import { GitHub, Linkedin, UpWork } from 'components/Icon'

const ContactWrapper = styled.div`
    display: flex;
    ${props => `
        margin-top: ${props.theme.spacing.large};
        gap: ${props.theme.spacing.small};
    `}
`

const Contact = () => {
  return (
    <ContactWrapper>
        <a href="https://github.com/jacoborope" aria-label='Github profile Link' rel="noopener noreferrer nofollow" target="_blank">
            <GitHub />
        </a>
        <a href="https://www.linkedin.com/in/jacoborope" aria-label='Linkedin profile link' rel="noopener noreferrer nofollow" target="_blank">
            <Linkedin />
        </a>
        <a href="https://www.upwork.com/freelancers/jacoboromeroperez" aria-label='Upwork profile link' rel="noopener noreferrer nofollow" target="_blank">
            <UpWork />
        </a>
    </ContactWrapper>
  )
}

export default Contact
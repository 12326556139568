import * as React from 'react'

import Container from 'components/Container'
import Layout from 'components/layout'
import Seo from 'components/seo'
import Avatar from 'components/Avatar'
import Contact from 'components/Contact'

const IndexPage = () => {
  return (
    <Layout>
      <Seo title="Welcome" />
      <Container>
        <Avatar />
        <Contact />
      </Container>
    </Layout>
  )
}

export default IndexPage

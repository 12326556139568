import React from 'react'

import styled from 'styled-components'

import Img from 'components/Image'

import emoji from "images/me_emoji.png"

const AvatarWrapper = styled.div`
        display: flex;
        justify-content: center;
        border-radius: 50%;
        height: 128px;
        width: 128px;
    ${props => `
        background-color: ${props.theme.colors.primary};
        border: 6px solid ${props.theme.colors.secondary};
    `}
`

const Avatar = () => {
  return (
    <AvatarWrapper>
        <Img
            xxl
            src={emoji}
            alt="emoji"
        />
    </AvatarWrapper>
  )
}

export default Avatar
import styled from 'styled-components'

import { AiFillGithub, AiFillLinkedin } from "react-icons/ai";
import { BiLogoUpwork } from "react-icons/bi"

export const GitHub = styled(AiFillGithub)`
  width: 24px;
  height: 24px;
  ${props => `
        fill: ${props.theme.colors.secondary};
  `}
`;

export const Linkedin = styled(AiFillLinkedin)`
  width: 24px;
  height: 24px;
  ${props => `
        fill: ${props.theme.colors.secondary};
  `}
`;

export const UpWork = styled(BiLogoUpwork)`
  width: 24px;
  height: 24px;
  ${props => `
        stroke: ${props.theme.colors.secondary};
  `}
`;